/* ==========================================================================
   #FORMS
   ========================================================================== */


label,
select,
textarea,
input:not([type="checkbox"]):not([type="radio"]) {
    display: block;
    width: 100%;
}

label {
    margin-bottom: $inuit-global-spacing-unit-small;
    @include inuit-font-size(13px, 1.2);
    pointer-events: none;
}

select,
textarea,
input:not([type="checkbox"]):not([type="radio"]) {
    padding: $inuit-global-spacing-unit-small;
    @include inuit-font-size(15px, 1);
    background: color(white);
    border: none;
    border-radius: 6px;
    font-family: $global-font-family;
    color: color(js-navy);
    outline: none;
    transition: box-shadow .3s ease-in-out;

    &:focus {
        box-shadow: 0 0 0 1px color(js-navy);
    }

    &::placeholder {
        @include inuit-font-size(14px, 1);
        font-style: italic;
    }

}





/* Checkboxes/Radio buttons
   ========================================================================== */

input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    left: -99999px;

    + label {
        position: relative;
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large;
        margin: 0;
        background: transparent;
        box-shadow: 0 0 0 transparent;
        border-radius: $global-radius;
        color: color(js-white);
        @include inuit-font-size(16px, 1.2);

        &::before {
            position: absolute;
            top: $inuit-global-spacing-unit-small;
            left: $inuit-global-spacing-unit-small;
            display: block;
            content: "";
            height: 20px;
            width: 20px;
            background: color(js-navy);
            border-radius: 999px;
            box-shadow: inset 0 0 0 1px color(js-navy);
            font-family: $icon-font-family;
            @include inuit-font-size(12px, 1.8);
            text-align: center;
        }

        &:hover {
            color: color(js-green);

            &::before {
                box-shadow: inset 0 0 0 1px color(js-green);
            }
        }
    }

    &:checked {
        + label {
            box-shadow: 0 0 0 1px color(js-green);
            background: rgba(color(js-green), 0.1);
            color: color(js-green);

            &::before {
                content: "\e901";
                background: color(js-green);
                box-shadow: inset 0 0 0 1px color(js-green);
                color: color(js-white);
            }
        }
    }

}





/* Field
   ========================================================================== */

.field {
    position: relative;
    margin-bottom: $inuit-global-spacing-unit;
}

    .field__msg {
        position: absolute;
        top: 0;
        right: 0;
        color: color(js-red);
        opacity: 0;
        transform: translateY(50%);
        transition: opacity .15s ease-in-out, transform .15s ease-in-out;

        &.active {
            opacity: 1;
            transform: translateY(0);
        }
    }

.field--actions {
    position: fixed;
    top: $inuit-global-spacing-unit;
    right: $inuit-global-spacing-unit;
    z-index: 11;
}





/* Calendar date selections
   ========================================================================== */

.input-calendar {
    &,
    &:focus {
        background: transparent;
        box-shadow: none;
        text-transform: uppercase;
    }
}





/* Input with icon
   ========================================================================== */

.input-icon {
    position: relative;

    .input-icon__indicator {
        position: absolute;
        top: 50%;
        right: $inuit-global-spacing-unit-small;
        z-index: 2;
        transform: translateY(-50%);
        line-height: 1;
    }
}





/* States
   ========================================================================== */

.disabled,
[disabled] {
    opacity: 0.5;
    pointer-events: none;
}

.error {
    select:not(.input-calendar),
    textarea,
    input:not([type="checkbox"]):not([type="radio"]),
    input[type="checkbox"] + label::before,
    input[type="radio"] + label::before {
        box-shadow: 0 0 0 1px color(js-red);
    }
}





/* Misc
   ========================================================================== */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}




