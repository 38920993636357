/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually {
    @include inuit-hidden-visually();
}


/**
 * Hide visually and from screen readers.
 */

.u-hidden {
    display: none !important;
}

.u-visible-inline-block\@md,
.u-visible\@sm,
.u-visible\@md,
.u-visible\@lg,
.u-visible\@xl {
    @extend .u-hidden;
}

.u-visible-visually\@sm,
.u-visible-visually\@md,
.u-visible-visually\@lg,
.u-visible-visually\@xl {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}





/* Responsive (Hidden)
   ========================================================================== */
@include mq($from: sm) {

    .u-visible\@sm { display: block !important; }
    .u-hidden\@sm { display: none !important; }

    .u-visible-visually\@sm {
        position: static !important;
        top: 0 !important;
        left: 0 !important;
    }

    .u-hidden-visually\@sm {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }

}

@include mq($from: md) {

    .u-visible\@md { display: block !important; }
    .u-visible-inline-block\@md { display: inline-block !important; }
    .u-hidden\@md { display: none !important; }

    .u-visible-visually\@md {
        position: static !important;
        top: 0 !important;
        left: 0 !important;
    }

    .u-hidden-visually\@md {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }

}

@include mq($from: lg) {

    .u-visible\@lg { display: block !important; }
    .u-hidden\@lg { display: none !important; }

    .u-visible-visually\@lg {
        position: static !important;
        top: 0 !important;
        left: 0 !important;
    }

    .u-hidden-visually\@lg {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }

}

@include mq($from: xl) {

    .u-visible\@xl { display: block !important; }
    .u-hidden\@xl { display: none !important; }

    .u-visible-visually\@xl {
        position: static !important;
        top: 0 !important;
        left: 0 !important;
    }

    .u-hidden-visually\@xl {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }

}




