/* ==========================================================================
   #Accordion
   ========================================================================== */

.c-accordion {
    padding: 68px 0;
    color: color(white);
    position: relative;
    transition: all .3s ease-in-out;
    cursor: pointer;

    p {
        color: color(white);
    }

    a {
        color: color(js-green);
        &:visited {
            color: color(js-green);
        }
    }

    &:hover {
    }

    &.open {
        padding: 30px 0;

        .c-accordion__inner {
            max-height: 1000px;
        }

        .f-bg-grain {
            opacity: 0 !important;
        }
    }

    h3 {
        transition: all .3s ease-in-out;
    }

    li {
        margin-bottom: 10px;
    }

}

    .c-accordion__outer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .c-accordion__inner {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-in-out;
        font-size: 18px;
    }

/* ==========================================================================
   #Style variants
   ========================================================================== */

.c-accordion--small {
    background-color: transparent;
    color: rgba(color(white), .4);
    padding: 0 0 40px 0;

    .c-accordion__btn {
        opacity: .4;
    }

    h4 {
        font-size: 28px !important;
    }

    &:hover {
        background-color: transparent;
    }

    &.open {
        color: color(white);
        background-color: transparent;
        padding: 0 0 40px 0;

        .c-accordion__btn {
            opacity: 1;
        }
    }

    .c-accordion__inner {
        font-size: 16px;
    }
}


/* ==========================================================================
   #Button styles and animations
   ========================================================================== */


    .c-accordion__btn {
      color: #fff;
      width: 60px;
      height: 60px;
      border: 0;
      font-size: 1.5em;
      position: relative;
    }

    .c-accordion__btn span {
      position: absolute;
      transition: 300ms;
      background: white;
    }

    /* Create the "+" shape by positioning the spans absolutely */
    .c-accordion__btn span:first-child {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }

    .c-accordion__btn span:last-child {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }

    /* Morph the shape when the .c-accordion__btn is hovered over */
    .c-accordion__btn.close span {
      transform: rotate(90deg);
    }

    .c-accordion__btn.close span:last-child {
      left: 50%;
      right: 50%;
    }

/* Responsive (Hidden)
   ========================================================================== */
@include mq($until: md) {
    .c-accordion {
        padding: 42px 0;

        h4 {
            font-size: 25px !important;
        }
    }
}

