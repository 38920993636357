/* ==========================================================================
   #FLAG
   ========================================================================== */

.o-flag {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0;
}

    .o-flag__img {
        position: relative;
        margin: 0 $inuit-global-spacing-unit 0 0;
        border-radius: $global-radius;
        overflow: hidden;

        > img {
            width: 100%;
        }
    }

    .o-flag__body {
        flex: 1;
        min-width: 0;

        &,
        > :last-child {
            margin-bottom: 0;
        }
    }





/* Size variants
   ========================================================================== */
.o-flag--flush {

    .o-flag__img {
        margin-right: 0;
    }

}

.o-flag--tiny {

    .o-flag__img {
        margin-right: $inuit-global-spacing-unit-tiny;
    }

}

.o-flag--small {

    .o-flag__img {
        margin-right: $inuit-global-spacing-unit-small;
    }

}

.o-flag--large {

    .o-flag__img {
        margin-right: $inuit-global-spacing-unit-large;
    }

}

.o-flag--huge {

    .o-flag__img {
        margin-right: $inuit-global-spacing-unit-huge;
    }

}





/* Alignment variants
   ========================================================================== */
.o-flag--top {
    align-items: flex-start;
}

.o-flag--bottom {
    align-items: flex-end;
}





/* Style variants
   ========================================================================== */
.o-flag--reverse {
    flex-direction: row-reverse;
}




