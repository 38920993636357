/* ==========================================================================
   #Modal
   ========================================================================== */

    .c-toast {
        position: fixed;
        top: -100%;
        left: 50%;
        transform: translateX(-50%);
        transition: top .3s ease-in-out;
        background: color(toast);
        z-index: 1000;
        padding: 15px;
        border-radius: 8px;
        font-size: 13px;
        line-height: 1.4;

        &.open {
            top: 52px;
        }

        p {
            margin-bottom: 0;

            &:first-of-type {
                font-weight: 700;
            }
        }

        .o-pin {
            color: color(white);
            background: color(icon-green);
            border-radius: 50%;
            width: 24px;
            height: 24px;
        }
    }