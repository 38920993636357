/* ==========================================================================
   #Marketing Sidebar
   ========================================================================== */

.c-sidebar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100vw);
    height: 100vh;
    width: 100vw;
    background-color: color(darker-blue);
    padding: $inuit-global-spacing-unit*6 0;
    transition: all .3s ease-in-out;
    z-index: 9;

    a {
        font-size: 28px;
        font-weight: 700;
        color: white;
    }

    &.open {
        transform: translateX(0);
    }
}

    .c-sidebar__item {

    }



/* Responsive
   ========================================================================== */
@include mq($from: md) {
    .c-sidebar {
        display: none;
        padding: $inuit-global-spacing-unit*3;
    }
}