/* ==========================================================================
   #HEADERS
   ========================================================================== */

.c-header {
    padding: $inuit-global-spacing-unit 0;
    background: color(darker-blue);
    color: color(white);
    z-index: 10;
    position: relative;
    transition: background .3s ease-in-out;

    a {
        color: white;
        font-weight: 700;
        font-size: 14px;

    }

    &.open {
        background: transparent;
    }

    .c-logo > svg {
        width: 200px;
    }
}




/* Responsive
   ========================================================================== */


@include mq($from: md) {

    .c-header {
        padding: $inuit-global-spacing-unit 80px;
    }

}