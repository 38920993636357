/* ==========================================================================
   #SELECTS
   ========================================================================== */

.c-select {
    position: relative;
    z-index: 3;
    display: block;
    border-radius: $global-radius;
    @include inuit-font-size(15px);
    cursor: pointer;
    transition: z-index 0s linear .3s;

    &::after {
        position: absolute;
        top: 50%;
        right: $inuit-global-spacing-unit-tiny*3;
        z-index: 2;
        display: block;
        content: "\e903";
        color: color(js-gray-alt-light);
        font-family: $icon-font-family;
        transform: translateY(-50%);
        pointer-events: none;
    }

    &.active {
        z-index: 4;
        transition: z-index 0s linear;

        &::after {
            content: "\e905";
        }

        > .c-select__list {
            pointer-events: auto;
            opacity: 1;
            transform: translateY(0);
        }
    }

}

    .c-select__select {
        display: block;
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large;
        background: color(white);
        color: color(js-navy);
        border-radius: $global-radius;
        line-height: 1.2;
        vertical-align: middle;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        [class^="o-icon-"],
        [class*=" o-icon-"] {
            position: absolute;
            top: 50%;
            left: $inuit-global-spacing-unit-tiny*3;
            transform: translateY(-50%);
        }
    }


    .c-select__placeholder {
        @include inuit-font-size(14px);
        font-style: italic;
    }

    .c-select__list {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1;
        overflow: scroll;
        width: 100%;
        max-height: 225px;
        padding: $inuit-global-spacing-unit-small;
        margin: 0;
        background: color(white);
        border-radius: $global-radius;
        color: color(js-navy);
        list-style: none;
        pointer-events: none;
        opacity: 0;
        transform-origin: top right;
        transform: translateY(-$inuit-global-spacing-unit-small);
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    }

        .c-select__item {
            @extend .o-list-stack__item;
            font-weight: 400;
        }

    .c-select__input {
        position: absolute;
        left: -9999px;
    }

    input:not([type="checkbox"]):not([type="radio"]).c-select__search {
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large;
        box-shadow: none;
    }




/* Date picker variant
   ========================================================================== */

.c-select--date {

    .c-select__select {
        padding-left: $inuit-global-spacing-unit-large;
    }

    .c-select__list {
        overflow: hidden;
        max-height: none;
        padding: 0;
    }
}





/* Multiple selection
   ========================================================================== */

.c-select--multiple:not(.c-select--date) {

    .c-select__select {
        padding: 0;
    }

}





/* No icon variant
   ========================================================================== */

.c-select--no-icon {

    .c-select__select {
        padding-left: $inuit-global-spacing-unit-small;
    }

    input:not([type="checkbox"]):not([type="radio"]).c-select__search {
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small;
    }

}





/* Position variants
   ========================================================================== */

.c-select--left {

    .c-select__list {
        right: auto;
        left: 0;
    }

}





/* Size variants
   ========================================================================== */

.c-select--auto {
    width: auto;
}





/* States
   ========================================================================== */

.error {
    .c-select__select {
        box-shadow: 0 0 0 1px color(js-red);
    }
}




