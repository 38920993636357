/* ==========================================================================
   #BRANDING
   ========================================================================== */

/* Color Mapping
   ========================================================================== */
@function color($color) {
    @if (map-has-key($brand-colors, $color) != true) {
        @error "`#{$color}` is not in your list of branding colors.";
    }

    @return map-get($brand-colors, $color);
}





/* Color Helpers
   ========================================================================== */
@if ($global-color-mappings == true) {
    @each $name, $value in $brand-colors-class-list {
        .u-color-#{$name} { color: $value; }
        a.u-color-#{$name}:hover { color: lighten($value, 20%); }
    }
}





/* Background Helpers
   ========================================================================== */
@if ($global-background-mappings == true) {
    @each $name, $value in $brand-backgrounds-class-list {
        .u-background-#{$name} { background: $value; }
    }
}
