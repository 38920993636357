/* ==========================================================================
   #Playlist
   ========================================================================== */

    .c-playlist {
        background: color(navy-alt);
        color: color(white);
        z-index: 100;
        min-height: 100%;
        padding: $inuit-global-spacing-unit-tiny*14 0;

        p {
            color: color(js-gray-alt-light);
            font-size: 15px;

            &.u-color-js-green {
                color: color(js-green);
            }
        }
    }


