/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

/* Typography properties
   ========================================================================== */
$deci: 15px;
$centi: 14px;





/* Alignment
   ========================================================================== */
.u-align-left { text-align: left; }
.u-align-center { text-align: center; }
.u-align-right { text-align: right; }


/* Font
   ========================================================================== */
.vag {font-family: 'VAG'; font-weight: 800}


/* Sizes
   ========================================================================== */
.u-deci { @include inuit-font-size($deci, 1.4); }
.u-centi { @include inuit-font-size($centi, 1.6); }
.fake-icon { height: 1rem; }



/* misc
   ========================================================================== */
.u-border-radius { border-radius: 8px; }
.u-cursor-pointer { cursor: pointer; }
.u-inline-block { display: inline-block; }
.u-underline { text-decoration: underline;}

.angle {
    position: relative;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 86%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 86%);
}

/* Responsive
   ========================================================================== */

@include mq($from: md) {
    .u-align-left\@md { text-align: left }
}





