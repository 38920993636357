///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;


// Show mapping helper classes `.u-color-`, `.u-background-`
$global-color-mappings: true;
$global-background-mappings: true;





/*----------  MAIN OVERRIDES  ----------*/

// Baseline grid lines height.
// Every spacing metric should be based on this.
$inuit-global-baseline: 5px !default;


// How many grid lines should our spacing unit variants span?
// Each value should be an unitless integer.
$inuit-global-spacing-unit-factor-tiny:   1 !default;   // 6px
$inuit-global-spacing-unit-factor-small:  2 !default;   // 12px
$inuit-global-spacing-unit-factor:        4 !default;   // 24px
$inuit-global-spacing-unit-factor-large:  8 !default;   // 48px
$inuit-global-spacing-unit-factor-huge:   16 !default;  // 96px
$inuit-global-spacing-unit-factor-massive:32;  // 96px


// Spacing values are determined based on your project’s global baseline grid.
// It is not recommended that you modify these following variables
// (it can break your vertical rhythm), but if you need to, you can.
$inuit-global-spacing-unit:       $inuit-global-baseline * $inuit-global-spacing-unit-factor !default;
$inuit-global-spacing-unit-tiny:  $inuit-global-baseline * $inuit-global-spacing-unit-factor-tiny !default;
$inuit-global-spacing-unit-small: $inuit-global-baseline * $inuit-global-spacing-unit-factor-small !default;
$inuit-global-spacing-unit-large: $inuit-global-baseline * $inuit-global-spacing-unit-factor-large !default;
$inuit-global-spacing-unit-huge:  $inuit-global-baseline * $inuit-global-spacing-unit-factor-huge !default;
$inuit-global-spacing-unit-massive:  $inuit-global-baseline * $inuit-global-spacing-unit-factor-massive;


// Base typographical styles and baseline grid. You need to define these values
// in pixels: inuitcss will convert them to more appropriate units.
$inuit-global-font-size: 18px;

$global-font-family: 'Lato', sans-serif !default;
$icon-font-family: 'jiosaavn' !default;


// Custom breakpoints for sass mq
$mq-breakpoints: (
    sm: 320px,
    md: 740px,
    lg: 980px,
    xl: 1300px
) !default;


// Show the breakpoints for testing
// $mq-show-breakpoints: (sm, md, lg, xl) !default;





/*----------  WIDTHS OVERRIDES  ----------*/

// Which fractions would you like in your grid system(s)? By default, inuitcss
// provides you fractions of one whole, halves, thirds, quarters and fifths.
$inuit-fractions: 1 2 3 4 5 !default;


// Optionally, inuitcss can generate classes to offset items by a certain width.
// Would you like to generate these types of class as well?
$inuit-offsets: false !default;


// By default, inuitcss uses fractions-like classes like `<div class="u-1/4">`.
// You can change the `/` to whatever you fancy with this variable.
$inuit-widths-delimiter: \/ !default;


// When using Sass-MQ, this defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
// <div class="u-3/12@sm">
$inuit-widths-breakpoint-separator: \@ !default;





/*----------  SPACING OVERRIDES  ----------*/

// Utility classes to put specific spacing values onto elements. The below loop
// will generate us a suite of classes like:
//
//   .u-margin-top {}
//   .u-margin-right-small {}
//   .u-padding {}
//   .u-padding-right-none {}
//   .u-padding-horizontal {}
//   .u-padding-vertical-small {}

$inuit-spacing-directions: (
    null: null,
    "-right": "-right",
    "-bottom": "-bottom",
    "-top": "-top",
    "-horizontal": "-left" "-right",
    "-vertical": "-top" "-bottom",
) !default;

$inuit-spacing-properties: (
    "padding": "padding",
    "margin": "margin",
) !default;

$inuit-spacing-sizes: (
    null: $inuit-global-spacing-unit,
    "-tiny": $inuit-global-spacing-unit-tiny,
    "-small": $inuit-global-spacing-unit-small,
    "-large": $inuit-global-spacing-unit-large,
    "-huge": $inuit-global-spacing-unit-huge,
    "-massive": $inuit-global-spacing-unit-massive,
    "-none": 0
) !default;





/*----------  RESPONSIVE SPACING OVERRIDES  ----------*/

// The responsive spacings just make sense and work properly when the ‘normal’
// spacings are included, too. In case they're not, we set `_utilities.spacings`
// to `null`.
$inuit-spacing-directions: null !default;


// When using Sass-MQ, this defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
//
//   <div class="u-margin-bottom@sm">
//
// Be aware that since the `@` symbol is a reserved symbol in CSS, it has to be
// escaped with a `\`. In the markup though, you write your classes without the
// backslash (e.g. `u-margin-bottom@sm`).
$inuit-widths-breakpoint-separator: \@ !default;


// Utility classes to put specific spacing values onto elements based on breakpoint. The below loop
// will generate us a suite of classes like:
//
//   .u-margin-top@sm {}
//   .u-margin-right-small@sm {}
//   .u-padding@sm {}
//   .u-padding-right-none@sm {}
//   .u-padding-horizontal@sm {}
//   .u-padding-vertical-small@sm {}

$inuit-responsive-spacing-directions: (
    null: null,
    "-right": "-right",
    "-top": "-top",
    "-bottom": "-bottom",
    "-horizontal": "-left" "-right",
    "-vertical": "-top" "-bottom",
) !default;

$inuit-responsive-spacing-properties: (
    "padding": "padding",
    "margin": "margin",
) !default;

$inuit-responsive-spacing-sizes: (
    null: $inuit-global-spacing-unit,
    "-tiny": $inuit-global-spacing-unit-tiny,
    "-small": $inuit-global-spacing-unit-small,
    "-large": $inuit-global-spacing-unit-large,
    "-huge": $inuit-global-spacing-unit-huge,
    "-massive": $inuit-global-spacing-unit-massive,
    "-none": 0
) !default;




