/* ==========================================================================
   #PAGE
   ========================================================================== */

@use "sass:math";

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
    font-family: $global-font-family;
    font-size: math.div($inuit-global-font-size, 16px) * 1em; /* [1] */
    line-height: math.div($inuit-global-line-height, $inuit-global-font-size); /* [1] */
    overflow-y: scroll; /* [2] */
    min-height: 100%; /* [3] */
}

body {
    background: color(dark-blue-alt);
    color: color(text);

    &.no-scroll {
        height: 100vh;
        overflow-y: hidden;
        position: fixed;
    }
}

a {
    text-decoration: none;
}



section {
    position: relative;
    overflow: hidden;
}

/* Responsive
   ========================================================================== */


@include mq($from: md) {
    .hero {
        background: color(pink);
        border-left: 20px solid color(dark-blue-alt);
        border-right: 20px solid color(dark-blue-alt);
    }
}


