/* ==========================================================================
   #LOGOS
   ========================================================================== */

.c-logo {
    display: inline-block;
    width: 67px;
    overflow: hidden;

    & > svg { fill: color(js-navy); }
    width: 250px;
    font-size: 0 !important;
    overflow: hidden;
}





/* Responsive
   ========================================================================== */





