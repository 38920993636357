/* ==========================================================================
   #Modal
   ========================================================================== */

   .c-modal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease-in-out;
        z-index: 100;
        overflow-y: scroll;

        &.open {
            opacity: 1;
            pointer-events: auto;
        }
   }

    .c-modal__close {
        position: absolute;
        top: 32px;
        right: 32px;

        .o-icon-close {
            font-size: 10px;
        }
    }



/* Responsive
   ========================================================================== */


@include mq($from: md) {
    .c-modal__close {
        top: 50px;
        right: 60px;
    }
}