/* ==========================================================================
   #Player
   ========================================================================== */

.c-player {
    color: color(white);
    margin-bottom: $inuit-global-spacing-unit-large;

    p {
        color: white;
    }
}
    .c-player__seek {
        height: 5px;
        background: #3D404C;
        position: relative;
        cursor: pointer;
    }

    .c-player__seek-head {
        width: 18px;
        height: 18px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        background: color(white);
        position: absolute;
        transition: left .1s linear;
    }

    .c-player__seek-fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        background: color(js-green);
        pointer-events: none;
    }

