/* ==========================================================================
#ONE OFFS
========================================================================== */

.hero {
    background-clip: padding-box;
    border-left: 15px solid #D0527F;
    border-top: 15px solid #D0527F;
    border-right: 15px solid #D0527F;
    border-bottom: 15px solid #D0527F;
}

.hearts-bg {
    background-size: 0;
    background-repeat: no-repeat;
}

.hero,
.form-section {
    background-size: 30%;
}

.square-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30%;
}

.spiral-bg {
    position: absolute;
    top: -20%;
    right: -20%;
    width: 60%;
}

.sidebar-bg {
    position: absolute;
    bottom: -30%;
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
}

.home-hero {
    position: relative;
}

.tiny-hearts {
    position: absolute;
    top: -10%;
    width: 120%;
    left: 50%;
    transform: translateX(-50%);
}

.home-heart {
    padding-top: 50px;
}

.home-pack {
    position: absolute;
    bottom: -30%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
}
.heartbeat {
  animation: heartbeat 2s ease 10 forwards;
}

.bounce {
  animation: bounce 2s ease 10 forwards;
}

.cf-logo {
    width: 144px;
}

@keyframes bounce {
    70% { transform: translateX(-50%) translateY(0%); }
    80% { transform: translateX(-50%) translateY(-30%); }
    90% { transform: translateX(-50%) translateY(0%); }
    95% { transform: translateX(-50%) translateY(-20%); }
    97% { transform: translateX(-50%) translateY(0%); }
    99% { transform: translateX(-50%) translateY(-10%); }
    100% { transform: translateX(-50%) translateY(0); }
}


@keyframes heartbeat
{
  0%
  {
    transform: scale( 1 );
  }
  20%
  {
    transform: scale( .75 );
  }
  40%
  {
    transform: scale( 1 );
  }
  60%
  {
    transform: scale( .75 );
  }
  80%
  {
    transform: scale( 1 );
  }
  100%
  {
    transform: scale( 1 );
  }
}

.legalese {
    font-size: 12px;
    p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: center; }
    p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; ; min-height: 13.0px}
    p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; }
    p.p5 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; ; color: #000000; min-height: 13.0px}
    p.p6 {margin: 0.0px 0.0px 0.0px 36.0px; text-align: justify; }
    p.p7 {margin: 0.0px 0.0px 0.0px 36.0px; text-align: justify; ; min-height: 13.0px}
    p.p8 {margin: 0.0px 0.0px 0.0px 72.0px; text-align: justify; ; min-height: 13.0px}
    p.p9 {margin: 0.0px 0.0px 0.0px 72.0px; text-align: justify; ; color: #000000; min-height: 13.0px}
    p.p10 {margin: 0.0px 0.0px 0.0px 108.0px; text-align: justify; ; min-height: 13.0px}
    p.p11 {margin: 0.0px 0.0px 0.0px 54.0px; text-align: justify; }
    p.p12 {margin: 0.0px 0.0px 0.0px 54.0px; text-align: justify; ; min-height: 13.0px}
    p.p13 {margin: 0.0px 0.0px 0.0px 90.0px; text-align: justify; }
    p.p14 {margin: 0.0px 0.0px 0.0px 13.5px; text-align: justify; ; color: #000000}
    li.li3 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; }
    li.li4 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; ; color: #000000}
    li.li15 {margin: 6.0px 0.0px 6.0px 0.0px; text-align: justify; }
    span.s1 {text-decoration: underline}
    span.s2 {text-decoration: underline ; color: #0563c1}
    span.s3 {text-decoration: underline ; color: #1155cc}
    span.s4 {color: #ff0000}
    span.s5 {color: #000000}
    span.s6 {text-decoration: underline ; color: #000000}
    span.Apple-tab-span {white-space:pre}
    ul.ul1 {list-style-type: disc}
    ul {margin-bottom: 0;}
}



/* Responsive
   ========================================================================== */


@include mq($from: md) {
    .hero {
        border-top: 20px solid #D0527F;
        border-left: 20px solid #D0527F;
        border-right: 20px solid #D0527F;
        border-bottom: 20px solid #D0527F;
    }

    .hero,
    .form-section {
        background-size: 10%;
    }

    .hearts-bg {
        background-size: contain;
    }

    .square-bg {
        width: 20%;
    }

    .spiral-bg {
        top: -50%;
        width: 50%;
    }

    .legalese {
        font-size: 18px;
    }
}
