///* ========================================================================
//   #FONT-SIZE
//   ======================================================================== */

@use "sass:math";

// Generates a rem font-size (with pixel fallback) and a baseline-compatible
// unitless line-height from a pixel font-size value. Basic usage is simply:
//
//   @include inuit-font-size(18px);
//
// You can force a specific line-height by passing it as the second argument:
//
//   @include inuit-font-size(16px, 1);
//
// You can also modify the line-height by increments, while staying in the
// baseline grid, by setting the `$modifier` parameter. It takes a positive
// or negative integer, and it will add or remove "lines" to the  generated
// line-height. This is the recomended way to do it, unless you really need
// an absolute value. i.e.:
//
//   // add 2 lines:
//   @include inuit-font-size(24px, $modifier: +2);
//
//   // subtract 1 line:
//   @include inuit-font-size(24px, $modifier: -1);

@mixin inuit-font-size($font-size, $line-height: auto, $modifier: 0, $important: false) {

    @if (type-of($font-size) == number) {
        @if (unit($font-size) != "px") {
            @error "`#{$font-size}` needs to be a pixel value.";
        }
    } @else {
        @error "`#{$font-size}` needs to be a number.";
    }

    @if ($important == true) {
        $important: !important;
    } @else if ($important == false) {
        $important: null;
    } @else {
        @error "`#{$important}` needs to be `true` or `false`.";
    }

    // We provide a `px` fallback for old IEs not supporting `rem` values.
    font-size: $font-size $important;
    font-size: math.div($font-size, $inuit-global-font-size) * 1rem $important;

    @if ($line-height == "auto") {

        // Define how many grid lines each text line should span.
        // By default, we set it to the minimum number of lines necessary
        // in order to contain the defined font-size, +1 for some breathing room.
        // This can be modified with the `$modifier` parameter.
        $lines: math.ceil(math.div($font-size, $inuit-global-baseline)) + $modifier + 1;
        $line-height: $lines * $inuit-global-baseline;

        line-height: math.div($line-height, $font-size) $important;
    }

    @else {

        @if (type-of($line-height) == number or $line-height == "inherit" or $line-height == "normal") {
            line-height: $line-height $important;
        }

        @else if ($line-height != 'none' and $line-height != false) {
            @error "D’oh! `#{$line-height}` is not a valid value for `$line-height`."
        }

    }

}




