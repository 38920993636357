/* ==========================================================================
   #LOADERS
   ========================================================================== */

.c-loader {
    display: inline-block;
    height: $inuit-global-spacing-unit;
    width: $inuit-global-spacing-unit;
    margin: 0 6px;
    border: 2px solid color(js-white-alt);
    border-top-color: color(js-white-alt-dark);
    border-radius: 999px;
    vertical-align: middle;
    animation: SPIN_ME 300ms linear infinite;
}

.c-loading-cover {
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}





/* Position variations
   ========================================================================== */
.c-loader--fixed,
.c-loader--center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    margin: 0;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    animation: SPIN_ME_CENTER 300ms linear infinite;
}

.c-loader--fixed {
    position: fixed;

    &.c-loader--pushed {
        left: 62%;
    }
}





/* Color variations
   ========================================================================== */
.c-loader--alt {
    border-color: rgba(color(js-green), 0.6);
    border-top-color: color(js-green);
}

.c-loading-cover--alt {
    background: rgba(0,0,0, .3);
}





/* Size variations
   ========================================================================== */
.c-loader--small {
    height: $inuit-global-spacing-unit-small * 1.5;
    width: $inuit-global-spacing-unit-small * 1.5;
}

.c-loader--large {
    height: 32px;
    width: 32px;
}

.c-loader--xlarge {
    height: 46px;
    width: 46px;
    border-width: 3px;
}





/* Animation
   ========================================================================== */
@keyframes SPIN_ME {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes SPIN_ME_CENTER {
    0% { transform: rotate(0deg) translate(-50%, -50%); }
    100% { transform: rotate(360deg) translate(-50%, -50%); }
}





/* Progress bar (third-party)
   ========================================================================== */
#nprogress .bar {
    height: 3px;
    background: color(js-green);
}




