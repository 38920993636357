/* ==========================================================================
   #WIDTHS
   ========================================================================== */

@use "sass:math";

/**
 * inuitcss generates a series of utility classes that give a fluid width to
 * whichever element they’re applied, e.g.:
 *
 *   <img src="" alt="" class="u-1/2" />
 *
 * These classes are most commonly used in conjunction with our layout system,
 * e.g.:
 *
 *   <div class="o-layout__item  u-1/2">
 *
 * By default, inuitcss will also generate responsive variants of each of these
 * classes by using your Sass MQ configuration, e.g.:
 *
 *   <div class="o-layout__item  u-1/1  u-1/2@md  u-1/3@lg">
 *
 * Optionally, inuitcss can generate offset classes which can push and pull
 * elements left and right by a specified amount, e.g.:
 *
 *   <div class="o-layout__item  u-2/3  u-pull-1/3">
 *
 * This is useful for making very granular changes to the rendered order of
 * items in a layout.
 *
 * N.B. This option is turned off by default.
 */


// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include widths(3 4, -sm);

@mixin inuit-widths($columns, $breakpoint: null) {

    // Loop through the number of columns for each denominator of our fractions.
    @each $denominator in $columns {

        // Begin creating a numerator for our fraction up until we hit the
        // denominator.
        @for $numerator from 1 through $denominator {

            // Build a class in the format `.u-3/4[@<breakpoint>]`.

            .u-#{$numerator}#{$inuit-widths-delimiter}#{$denominator}#{$breakpoint} {
                width: math.div($numerator, $denominator) * 100% !important;
                flex: 0 0 math.div($numerator, $denominator) * 100% !important;
            }

            @if ($inuit-offsets == true) {

                /**
                * 1. Reset any leftover or conflicting `left`/`right` values.
                */

                // Build a class in the format `.u-push-1/2[@<breakpoint>]`.

                .u-push-#{$numerator}#{$inuit-widths-delimiter}#{$denominator}#{$breakpoint} {
                    position: relative !important;
                    right: auto !important; /* [1] */
                    left: math.div($numerator, $denominator) * 100% !important;
                }

                // Build a class in the format `.u-pull-5/6[@<breakpoint>]`.

                .u-pull-#{$numerator}#{$inuit-widths-delimiter}#{$denominator}#{$breakpoint} {
                    position: relative !important;
                    right: math.div($numerator, $denominator) * 100% !important;
                    left: auto !important; /* [1] */
                }

            }

        }

    }

    @if ($inuit-offsets == true and $breakpoint != null) {

        // Create auto push and pull classes.

        .u-push-none#{$breakpoint} {
            left: auto !important;
        }

        .u-pull-none#{$breakpoint} {
            right: auto !important;
        }

    }

}





/**
 * A series of width helper classes that you can use to size things like grid
 * systems. Classes take a fraction-like format (e.g. `.u-2/3`). Use these in
 * your markup:
 *
 * <div class="u-7/12">
 *
 * The following will generate widths helper classes based on the fractions
 * defined in the `$inuit-fractions` list.
 */

@include inuit-widths($inuit-fractions);





/**
 * If we’re using Sass-MQ, automatically generate grid system(s) for each of our
 * defined breakpoints, and give them a Responsive Suffix, e.g.:
 *
 * <div class="u-3/12@md">
 */

$mq-breakpoints-tmp: map-remove($mq-breakpoints, sm);

@if (variable-exists(mq-breakpoints-tmp)) {

    @each $inuit-bp-name, $inuit-bp-value in $mq-breakpoints-tmp {

        @include mq($from: $inuit-bp-name) {
            @include inuit-widths($inuit-fractions, #{$inuit-widths-breakpoint-separator}#{$inuit-bp-name});
        }

    }

}




