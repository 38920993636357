///* ========================================================================
//   #BRAND
//   ======================================================================== */

// A map of the brand colors
$brand-colors: (
    // JIOSAAVN PRIMARY
    js-green:              #2bc5b4, // Default Highlight
    js-gray:               #3e3e3e, // Default Copy
    js-white:              #f6f6f6, // Default Background Light
    js-navy:               #2a2d36, // Default Background Dark

    // JIOSAAVN SECONDARY - GREEN
    js-green-alt-light:    #b6fff7,
    js-green-alt:          #6effef,
    js-green-alt-dark:     #258f83,

    // JIOSAAVN SECONDARY - GRAY
    js-gray-alt-light:     #a9a9a9,
    js-gray-alt:           #707070,
    js-gray-alt-dark:      #202020,

    // JIOSAAVN SECONDARY - WHITE
    js-white-alt:          #e9e9e9,
    js-white-alt-dark:     #cccccc,

    // JIOSAAVN SECONDARY - OTHER
    js-navy-alt:           #828da8,
    js-red:                #f3464a,

    // STANDARD
    white:                  #ffffff,

    // Site Specific
    blue:                   #013BB9,
    dark-blue:              #0033A1,
    darker-blue:            #042979,
    dark-blue-alt:          #224381,
    navy-alt:               #00133C,
    gray-alt-dark:          #22252E,
    text:                   #3C3C3C,
    pink:                   #F29FC5,
    light-blue:             #E0EAFF,
    charcoal:               #292D39,
    toast:                  #EFF7EE,
    icon-green:             #75B36A,
);





/* Colors class list
   ========================================================================== */
$brand-colors-class-list: (
    white:                  #ffffff,
    text:                   #3C3C3C,
    dark-blue:              #0033A1,
    js-gray-alt:           #707070,
    js-navy:               #2a2d36, // Default Background Dark
    pink:                   #F29FC5,
    dark-blue-alt:          #224381,
    js-gray-alt-light:     #a9a9a9,
    js-green:              #2bc5b4, // Default Highlight
);




/* Backgrounds class list
   ========================================================================== */
$brand-backgrounds-class-list: (
    white:                  #ffffff,
    light-blue:             #E0EAFF,
    gray-alt-dark:          #22252E,
    dark-blue:              #0033A1,
    pink:                   #F29FC5,
    dark-blue-alt:          #224381,
    darker-blue:            #042979,
);




