/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */


// We have all of our heading font sizes defined here. Passing these pixel
// values into our `inuit-font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

$inuit-font-size-h1:  31px !default;
$inuit-font-size-h2:  24px !default;
$inuit-font-size-h3:  22px !default;
$inuit-font-size-h4:  20px !default;
$inuit-font-size-h5:  20px !default;
$inuit-font-size-h6:  15px !default;


h1 {
  @include inuit-font-size($inuit-font-size-h1);
}

h2 {
  @include inuit-font-size($inuit-font-size-h2);
}

h3 {
  @include inuit-font-size($inuit-font-size-h3);
}

h4 {
  @include inuit-font-size($inuit-font-size-h4);
}

h5 {
  @include inuit-font-size($inuit-font-size-h5);
}

h6 {
  @include inuit-font-size($inuit-font-size-h6);
}

/* Responsive
   ========================================================================== */
@include mq($from: lg) {

    h1 { @include inuit-font-size(49px, 1.2); }

    h2 { @include inuit-font-size(34px, 1.2); }

    h3 { @include inuit-font-size(34px, 1.2); }

    h4 { @include inuit-font-size(18px, 1.4); }

}
