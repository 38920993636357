/* ==========================================================================
   #RESPONSIVE-SPACINGS
   ========================================================================== */

/**
 * Utility classes enhancing the normal spacing classes by adding responsiveness
 * to them. By default, there are not responsive spacings defined. You can
 * generate responsive spacings by adding entries to the following three Sass
 * maps, e.g.:
 *
 *   $inuit-responsive-spacing-directions: (
 *     null: null,
 *     bottom: bottom,
 *   );
 *
 *   $inuit-responsive-spacing-properties: (
 *     "margin": "margin",
 *   );
 *
 *   $inuit-responsive-spacing-sizes: (
 *     "-small": $inuit-global-spacing-unit-small,
 *   );
 *
 * This would bring us the following classes:
 *
 *   .u-margin-small@md {}
 *   .u-margin-small@md {}
 *   .u-margin-small@lg {}
 *   .u-margin-small@xl {}
 *   .u-margin-bottom-small@md {}
 *   .u-margin-bottom-small@md {}
 *   .u-margin-bottom-small@lg {}
 *   .u-margin-bottom-small@xl {}
 *
 * You can change the generated CSS classes by further extending the Sass maps.
 * If you want every ‘normal’ spacing (those from `utilities.spacings`) also as
 * a responsive version, you can just mirror the ‘normal’ spacings:
 *
 *   $inuit-responsive-spacing-directions: $inuit-spacing-directions !default;
 *
 *   $inuit-responsive-spacing-properties: $inuit-spacing-properties !default;
 *
 *   $inuit-responsive-spacing-sizes: $inuit-spacing-sizes !default;
 *
 * BUT BE AWARE: This can generate a huge chunk of extra CSS, depending on the
 * amount of breakpoints you defined. So please check your CSS’ output and
 * filesize!
 */

// If the ‘normal’ spacings partial is not included, we provide an error message
// to indicate this.
@if $inuit-spacing-directions == null {
    @error "In order to use responsive spacings, you also need to include `_utilities.spacings.scss`!";
}
@else {

$mq-breakpoints-tmp: map-remove($mq-breakpoints, sm);

/* stylelint-disable max-nesting-depth */

// Don't output anything if no responsive spacings are defined.
@if ($inuit-responsive-spacing-properties != null) {

    @each $property-namespace, $property in $inuit-responsive-spacing-properties {

        @each $direction-namespace, $direction-rules in $inuit-responsive-spacing-directions {

            @each $size-namespace, $size in $inuit-responsive-spacing-sizes {

                @each $inuit-bp-name, $inuit-bp-value in $mq-breakpoints-tmp {

                    @include mq($from: $inuit-bp-name) {

                        .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace}#{$inuit-widths-breakpoint-separator}#{$inuit-bp-name} {

                            @each $direction in $direction-rules {
                                #{$property}#{$direction}: $size !important;
                            }

                        }

                    }

                }

            }

        }

    }

}

/* stylelint-enable max-nesting-depth */

}
